import React from 'react'

import styles from '../layouts/styles.module.css'

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: '',
    }
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: 'SUCCESS' })
      } else {
        this.setState({ status: 'ERROR' })
      }
    }
    xhr.send(data)
  }

  renderForm() {
    const { status } = this.state
    return (
      <>
        <h2>Request A Brochure</h2>
        <p>Please complete the form below and we will send you a brochure.</p>
        <label htmlFor='name'>
          <p>Name</p>
          <input type='text' name='name' id='name' />
        </label>
        <label htmlFor='email'>
          <p>Email</p>
          <input type='email' name='email' id='email' />
        </label>
        <label htmlFor='company'>
          <p>Company</p>
          <input type='text' name='company' id='company' />
        </label>
        <label htmlFor='message'>
          <p>Message</p>
          <textarea type='text' name='message' id='message' />
        </label>
        <button type='submit'>Send</button>
        { status === 'ERROR' && <p>Something went wrong. Please try again.</p> }
      </>
    )
  }

  renderThanks() {
    return (
      <>
        <h2>Great!</h2>
        <p>We will get back to you shortly.</p>
      </>
    )
  }

  render() {
    const { status } = this.state
    return (
      <form
        className={ styles.form }
        onSubmit={ this.submitForm }
        action='https://formspree.io/f/xeqprgwd'
        method='POST'
      >
        {
          status === 'SUCCESS'
            ? this.renderThanks()
            : this.renderForm()
        }
      </form>
    )
  }
}
