/* @flow */
import * as React from 'react'
import { Fragment } from 'react'
import cx from 'classnames'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import styles from '../layouts/styles.module.css'

import { SquareContainer, SquareRow, SquareBox } from '../layouts'

import ContactForm from '../layouts/contact_form'

const ImgContactUsMap = '../images/retail_smart.webp';

const IndexPage = (): React.Node => (

<Fragment>

    <section className={cx(styles.hero)}>
      <div>
        <div>
          <p>
            <strong>Ready</strong> to transform your business? <br />
            <strong>We are</strong> too.
          </p>
        </div>
      </div>
    </section>


    <section className={cx(styles.contact_us)}>
      <SquareContainer>
        <SquareRow>
          <SquareBox className={cx(styles.form_square)} id="contact-form"><ContactForm /></SquareBox>
          <StaticImage className={styles.square_box} layout="constrained" objectFit="cover" src={ImgContactUsMap} />
      </SquareRow>
    </SquareContainer>
</section>

</Fragment>
)


export default IndexPage
